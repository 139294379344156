import type { OrderDetailFragment, OrderDetailLineItemFragment, PaymentMethodFragment, ProductDetailFragment, ProductOptionFragment, ProductVariantFragment, SearchQuery, SearchResultFragment, ShippingMethodQuoteFragment } from '#graphql-operations'

export function useAnalytics() {
  const gtm = useNuxtApp().$gtm

  const state = useStore()
  const route = useRoute()
  const activeChannel = computed(() => state.value.activeChannel)
  const { activeOrder } = useActiveOrder()

  const currency = activeOrder.value?.currency ?? activeChannel.value?.currency

  // Abstraction for GA4 Ecommerce events
  const ecommerce = {
    addPaymentInfo: (paymentMethod?: PaymentMethodFragment) => {
      if (!paymentMethod)
        return

      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'add_payment_info',
        ecommerce: {
          currency,
          value: formatPrice(activeOrder.value?.total),
          coupon: '',
          payment_type: paymentMethod.name,
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      })
    },
    addShippingInfo: (shippingMethod: Pick<ShippingMethodQuoteFragment, 'id' | 'code' | 'name' | 'description'>) => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'add_shipping_info',
        ecommerce: {
          currency,
          value: formatPrice(activeOrder.value?.total),
          coupon: '',
          shipping_tier: shippingMethod.name,
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      })
    },
    addToCart: (productVariantOrOrderLine: ProductVariantFragment | OrderDetailLineItemFragment, quantity: number) => {
      gtm.push({ ecommerce: null })
      if ('linePrice' in productVariantOrOrderLine) {
        const line = productVariantOrOrderLine
        gtm.push({
          event: 'add_to_cart',
          ecommerce: {
            currency,
            value: formatPrice(line.unitPrice * quantity), // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
            items: [{
              item_id: line.productVariant.id,
              item_name: line.productVariant.product.name,
              item_variant: variantName(line.productVariant.options),
              discount: 0,
              price: formatPrice(line.unitPrice),
              quantity, // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
            }],
          },
        })
      }
      else {
        const productVariant = productVariantOrOrderLine
        gtm.push({
          event: 'add_to_cart',
          ecommerce: {
            currency: productVariant.currency,
            value: formatPrice(productVariant.price * quantity),
            items: [{
              item_id: productVariantOrOrderLine.id,
              item_name: productVariantOrOrderLine?.product?.name ?? productVariantOrOrderLine.name,
              item_variant: variantName(productVariantOrOrderLine.options),
              discount: 0,
              price: formatPrice(productVariant.price),
              quantity,
            }],
          },
        })
      }
    },
    addToWishlist: () => { /* ... */ },
    beginCheckout: () => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'begin_checkout',
        ecommerce: {
          currency,
          value: formatPrice(activeOrder.value?.total),
          coupon: '',
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      })
    },
    purchase: (order: OrderDetailFragment) => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.code,
          value: formatPrice(order.total),
          currency: order.currency,
          items: order.lines.map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      })
    },
    refund: () => { /* ... */ },
    removeFromCart: (line: OrderDetailLineItemFragment, quantity: number) => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'remove_from_cart',
        ecommerce: {
          currency,
          value: formatPrice(line.unitPrice * quantity), // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
          items: [{
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.unitPrice),
            quantity, // quantity takes precedence over line.quantity because the original quantity is updated after the event is fired
          }],
        },
      })
    },
    selectItem: (item: SearchResultFragment) => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'select_item',
        item_list_id: route.name,
        item_list_name: route.meta.title ?? route.name,
        ecommerce: {
          currency,
          items: [{
            item_id: item.id,
            item_name: item.name,
            discount: 0,
            price: formatPrice(item.price),
            quantity: 1,
          }],
        },
      })
    },
    selectPromotion: () => { /* ... */ },
    viewCart: () => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: 'view_cart',
        ecommerce: {
          currency,
          value: formatPrice(activeOrder.value?.subTotal),
          items: (activeOrder.value?.lines ?? []).map(line => ({
            item_id: line.productVariant.id,
            item_name: line.productVariant.product.name,
            item_variant: variantName(line.productVariant.options),
            discount: 0,
            price: formatPrice(line.productVariant.price),
            quantity: line.quantity,
          })),
        },
      })
    },
    viewItem: (productOrVariant: ProductDetailFragment | ProductVariantFragment) => {
      gtm.push({ ecommerce: null })

      if ('variants' in productOrVariant) {
        const product = productOrVariant
        gtm.push({
          event: 'view_item',
          ecommerce: {
            currency,
            items: [{
              item_id: product.id,
              item_name: product.name,
              quantity: 1,
              price: formatPrice(product.variants[0].price),
            }],
          },
        })
      }
      else {
        const variant = productOrVariant
        gtm.push({
          event: 'view_item',
          ecommerce: {
            currency,
            items: [{
              item_id: variant.id,
              item_name: variant.name,
              quantity: 1,
              price: formatPrice(variant.price),
            }],
          },
        })
      }
    },
    viewItemList: (searchResult?: SearchQuery) => {
      if (searchResult) {
        gtm.push({ ecommerce: null })
        gtm.push({
          event: 'view_item_list',
          ecommerce: {
            currency,
            item_list_id: route.name,
            item_list_name: route.meta.title ?? route.name,
            items: (searchResult.search.items ?? []).map((item, index) => ({
              index,
              item_id: item.id,
              item_name: item.name,
              price: formatPrice(item.price),
              quantity: 1,
            })),
          },
        })
      }
    },
    viewPromotion: () => { /* ... */ },
  }

  return {
    ecommerce,
  }
}

const formatPriceCache = new Map<number, number>()
function formatPrice(n?: { min: number, max: number } | { value: number } | number) {
  if (n == null)
    return 0

  const num = typeof n === 'number' ? n : 'min' in n ? n.min : n.value

  if (formatPriceCache.has(num))
    return formatPriceCache.get(num)

  const formatted = num / 100
  formatPriceCache.set(num, formatted)

  return formatted
}

function variantName(options?: ProductOptionFragment[]) {
  // This is also used by vendure when creating the product variant name
  // https://github.com/vendure-ecommerce/vendure/blob/master/packages/admin-ui/src/lib/catalog/src/providers/product-detail/product-detail.service.ts#L120
  return (options ?? []).map(o => o.name).join(' ').trim()
}
